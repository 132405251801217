.background-container {
  position: relative;
  background-color: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.logo-container {
  position: absolute;
  top: 0;
  left: -7%;
}

.logo-svg {
  width: 70%;
  height: 100%;
}

.headline-container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  width: 80%;
}

.headline {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.secondary-headline-container {
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.secondary-headline-svg {
  width: 100%;
  height: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px 0;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  p {
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .logo-container {
    top: 2%;
    left: -8%;
  }

  .headline-container {
    top: 25%;
    width: 75%;
  }

  .headline {
    font-size: 40px;
  }

  .secondary-headline-container {
    bottom: 25%;
    left: 70%;
  }

  .secondary-headline-svg {
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .headline-container {
    top: 25%;
    width: 80%;
  }

  .headline {
    font-size: 50px;
  }

  .secondary-headline-container {
    bottom: 20%;
  }
}

@media (min-width: 1280px) {
  .logo-container {
    width: 70%;
    left: -5%;
  }

  .headline-container {
    top: 23%;
    width: 60%;
  }

  .secondary-headline-container {
    bottom: 15%;
  }

  .secondary-headline-svg {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .logo-svg {
    width: 63%;
  }

  .headline-container {
    top: 15%;
  }

  .secondary-headline-container {
    bottom: 14%;
  }
}

@media (min-width: 1920px) {
  .logo-svg {
    width: 60%;
  }

  .headline-container {
    top: 27%;
  }

  .secondary-headline-container {
    bottom: 15%;
  }

  .secondary-headline-svg {
    width: 35%;
  }
}
